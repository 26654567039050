<template>
  <div id="edit-connection" class="vasion-white-background">
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <span id="header">New Database Connection</span>

    <div id="main-section">
      <div id="connection-details">
        <div id="errors" class="vasion-error-text">
          {{ errors }}
        </div>
        <div class="row">
          <VasionInput
            id="connection-name"
            v-model="connectionName"
            class="row-field"
            title="CONNECTION NAME"
            inputType="top-white"
            name="connection-name"
            placeholder="Enter Name..."
            :width="'424'"
          />
        </div>

        <div class="row">
          <VasionInput
            id="server"
            v-model="server"
            class="row-field"
            title="SERVER"
            inputType="top-white"
            name="server"
            placeholder="Enter Server..."
            :width="'424'"
            @input="updateConnectionString"
          />
        </div>

        <div class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="authentication"
            class="row-field"
            :dataArray="authenticationList"
            width="424"
            title="AUTHENTICATION"
            placeholder="Select Authentication..."
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="updateConnectionString"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>

        <div class="row">
          <VasionInput
            v-if="isSqlServerUser"
            id="user-name"
            v-model="userName"
            class="row-field"
            title="USER NAME"
            inputType="top-white"
            name="user-name"
            :placeholder="usernamePlaceholder"
            :width="'205'"
            @input="updateConnectionString"
          />

          <VasionInput
            v-if="isSqlServerUser"
            id="password"
            v-model="password"
            class="row-field"
            title="PASSWORD"
            inputType="top-white"
            name="password"
            :placeholder="passwordPlaceholder"
            :width="'205'"
            @input="updateConnectionString"
          />
        </div>

        <div class="row">
          <VasionInput
            id="database"
            v-model="database"
            class="row-field"
            title="DATABASE"
            inputType="top-white"
            name="database"
            placeholder="Enter Database..."
            :width="'205'"
            @input="updateConnectionString"
          />

          <VasionInput
            id="connection-timeout"
            v-model="timeout"
            class="row-field"
            title="CONNECTION TIMEOUT (seconds)"
            inputType="top-white"
            name="connection-timeout"
            placeholder="0"
            :width="'205'"
            type="number"
            min="0"
            @input="updateConnectionString"
          />
        </div>
      </div>

      <div id="connection-preview">
        <span class="vasion-input-label-top">CONNECTION STRING</span>
        <div id="content">
          <textarea
            id="connection-textarea"
            v-model="connectionString"
            class="vasion-textarea"
            name="connection-textarea"
          />
        </div>
      </div>
    </div>

    <div id="footer">
      <div class="buttons-left">
        <VasionButton
          id="test-connection"
          name="test-connection"
          :classProp="'secondary'"
          :isDisabled="!formIsValid"
          @vasionButtonClicked="testConnection"
        >
          TEST
        </VasionButton>
      </div>
      <div class="buttons-right">
        <VasionButton
          id="cancel-connection"
          name="cancel-connection"
          :classProp="'secondary'"
          @vasionButtonClicked="cancelConnection"
        >
          Cancel
        </VasionButton>

        <VasionButton
          id="save-connection"
          name="save-connection"
          :classProp="'primary'"
          :isDisabled="!formIsValid"
          @vasionButtonClicked="saveConnection"
        >
          Save
        </VasionButton>
      </div>
    </div>

    <VasionSnackbar
      id="database-connection-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'EditDatabaseConnection',
  components: {
    Loading,
  },
  data: function () {
    return {
      authentication: '',
      connectionName: '',
      connectionString: '',
      database: '',
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      password: '',
      server: '',
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      timeout: '30',
      userName: '',
    }
  },
  computed: {
    authenticationList() { return [{ name: 'SQL Server User', value: 1 }, { name: 'Integrated User', value: 2 }] },
    connectionStringComputed() {
      let connectionText = ''
      if (this.isSqlServerUser) {
        connectionText = `Persist Security Info=False;Data Source=${this.server};Initial Catalog=${this.database};User ID=${this.userName};Password=${this.password};Connect Timeout=${this.timeout}`
      } else {
        connectionText = `Persist Security Info=False;Integrated Security=true;Data Source=${this.server};Initial Catalog=${this.database};Connect Timeout=${this.timeout}`
      }

      return connectionText
    },
    errors() {
      let errorText = ''
      if (!this.formIsValid) {
        errorText = 'Please provide valid values for all database connection fields below.'
      }

      if (this.timeout !== '' && parseInt(this.timeout, 10) < 0) {
        errorText = `${errorText} The value for 'Timeout' must be zero or greater.`
      }

      return errorText
    },
    formIsValid() {
      return this.connectionName !== '' && this.server !== '' && this.userName !== '' && this.password !== '' && this.database !== ''
      && this.timeout !== '' && parseInt(this.timeout, 10) >= 0
      && (this.authentication && (typeof this.authentication === 'object') && (this.authentication.name && this.authentication.name !== '') && (this.authentication.value && this.authentication.value !== ''))
    },
    isSqlServerUser() {
      return this.authentication && this.authentication.value === 1
    },
    passwordPlaceholder() {
      return this.isSqlServerUser
      ? 'Enter Password...'
      : 'N/A'
    },
    usernamePlaceholder() {
      return this.isSqlServerUser
      ? 'Enter User Name...'
      : 'N/A'
    },
  },
  watch: {
    authentication() {
      if (!this.isSqlServerUser) {
        this.userName = 'N/A'
        this.password = 'N/A'
      } else {
        this.userName = ''
        this.password = ''
      }
    },
    timeout() {
      if (parseInt(this.timeout, 10) < 0) {
        this.timeout = 0
        this.connectionString = this.connectionStringComputed
      }
    },
  },
  created() {
    this.connectionString = this.connectionStringComputed
  },
  methods: {
    cancelConnection() {
      this.$emit('cancelConnectionClick')
    },
    async saveConnection() {
      const payload = {
        id: 0,
        name: this.connectionName,
        connectionString: this.connectionString,
      }

      if (await this.$store.dispatch('fieldMapping/saveDatabaseConnection', payload)) {
        this.$emit('saveConnectionClick')
      } else {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Unable to save database connection.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    async testConnection() {
      this.isLoading = true

      const payload = {
        Value: this.connectionString,
      }

      if (await this.$store.dispatch('fieldMapping/testDatabaseConnection', payload)) {
        this.isLoading = false
        this.snackbarTitle = 'SUCCESS'
        this.snackbarSubTitle = 'Connection test successful.'
        this.snackbarImage = true
      } else {
        this.isLoading = false
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Connection test failed.'
        this.snackbarImage = false
      }
        this.showSnackbarBool = true
    },
    updateConnectionString() {
      this.connectionString = this.connectionStringComputed
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  #edit-connection {
    width: 933px;
    margin: 24px;

    #header {
      @include Headline;
    }

    #main-section {
      margin-top: 10px;
      height: 390px;
      display: flex;

      #connection-details {
        display: block;
        width: 440px;
        border-right: solid 1px $grey-200;

        .row {
          width: 100%;
          margin: 13px 0;
          display: flex;

          .row-field {
            margin: 0 14px 5px 0;
          }
        }
      }

      #connection-preview {
        width: 472px;
        padding: 15px 0 0 24px;

        #content textarea {
          width: 448px;
          height: 345px;
        }
      }
    }
  }

  #footer {
    margin: 15px 10px 0px 10px;

    .buttons-left {
      float: left;
    }

    .buttons-right {
      float: right;
    }

    #back {
      margin-left: 0;
      padding-left: 0;

      button {
        margin-left: 0;
        padding-left: 0;
      }
    }

    #start-workflow {
      margin-right: 0;
      padding-right: 0;

      button {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
</style>
