<template>
  <div id="edit-database-lookup" class="vap-page">
    <div id="edit-database-lookup-header" class="vap-page-header">
      <h1 id="header">{{ lookupTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          id="cancel-button"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>

        <VasionButton
          id="save-button"
          class="last-btn"
          name="save-button"
          classProp="primary"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>

    <div id="main-section" class="vap-page-content">
      <div id="lookup-details">
        <div class="row">
          <VasionInput
            id="lookup-name"
            v-model="lookupName"
            class="row-field"
            title="LOOKUP NAME"
            inputType="top-white"
            name="lookup-name"
            placeholder="Enter Name..."
            :width="'396'"
            @input="markDirty()"
          />

          <VasionDropList
            v-slot="slotItem"
            v-model="databaseConnection"
            class="row-field"
            :dataArray="databaseConnectionList"
            width="326"
            title="DATABASE CONNECTION"
            placeholder="Select Connection..."
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="markDirty()"
          >
            {{ slotItem.item.name }}
          </VasionDropList>

          <VasionButton
            name="add-database-connection"
            :icon="'VasionAddIcon'"
            title="Add Database Connection"
            class="add-database-connection-icon-button"
            @vasionButtonClicked="addConnectionClick(); markDirty()"
          />

          <VasionButton
            v-if="shouldDeleteConnectionShow"
            name="delete-database-connection"
            :icon="'VasionDeleteIcon'"
            title="Delete Database Connection"
            class="add-database-connection-icon-button"
            @vasionButtonClicked="deleteConnectionClick(); markDirty()"
          />
        </div>
        <div class="row">
          <span class="subheader">
            Fields
          </span>
        </div>
        <div class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="attributeForm"
            class="row-field"
            :dataArray="attributeFormList"
            width="396"
            :title="`${$formsLabel.toUpperCase()}`"
            :placeholder="`Select ${$formsLabel}...`"
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="markDirty()"
          >
            {{ slotItem.item.name }}
          </VasionDropList>

          <VasionDropList
            v-slot="slotItem"
            v-model="attributeField"
            class="row-field"
            :dataArray="attributeFieldList"
            width="396"
            title="FIELD"
            placeholder="Select Field..."
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="markDirty()"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>

        <div class="row">
          <VasionCheckbox
            id="default-lookup"
            name="default-lookup"
            class="row-field checkbox"
            :checked="defaultLookup"
            @change="toggleDefaultLookup();"
          >
            Default Lookup
          </VasionCheckbox>

          <VasionButton
            id="add-result"
            name="add-result"
            class="left-button"
            :classProp="'secondary'"
            title="Add Result"
            :isDisabled="!attributeFieldIsSelected || attributeFieldIsDocumentID"
            @vasionButtonClicked="addResult(); markDirty()"
          >
            Add Result
          </VasionButton>

          <VasionButton
            id="add-search-param"
            name="add-search-param"
            :classProp="'secondary'"
            title="Add Search Parameter"
            :isDisabled="!attributeFieldIsSelected"
            @vasionButtonClicked="addParameter(); markDirty()"
          >
            Add Search Parameter
          </VasionButton>
        </div>
      </div>

      <div id="lookup-preview">
        <span class="vasion-input-label-top">QUERY</span>
        <div id="content">
          <textarea
            id="query-textarea"
            ref="query"
            v-model="queryText"
            rows="1"
            class="vasion-textarea"
            name="query-textarea"
          />
        </div>
      </div>
    </div>

    <md-dialog id="add-connection-dialog" class="add-connection-dialog-window" :md-active.sync="showAddConnectionDialog" :md-click-outside-to-close="false">
      <EditDatabaseConnection @cancelConnectionClick="toggleAddConnectionDialog" @saveConnectionClick="connectionSaved" />
    </md-dialog>

    <VasionSnackbar
      id="database-lookup-snack"
      :showSnackbarBool.sync="showVasionSnackbar"
      :snackbarImage="vasionSnackbarImage"
      :snackbarSubTitle="vasionSnackbarSubTitle"
      :snackbarTitle="vasionSnackbarTitle"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
  </div>
</template>

<script>
import EditDatabaseConnection from '@/components/fieldMapping/EditDatabaseConnection.vue';

export default {
  name: 'EditDatabaseLookup',
  components: {
    EditDatabaseConnection,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      attributeField: {name: '', value: ''},
      attributeForm: '',
      attributeFormId: 0,
      databaseConnection: '',
      databaseConnectionId: '',
      defaultLookup: false,
      isDirty: false,
      lookupId: 0,
      lookupName: '',
      queryText: '',
      queryTextArea: null,
      showAddConnectionDialog: false,
      showLeaveDialog: false,
      showVasionSnackbar: false,
      vasionSnackbarImage: false,
      vasionSnackbarSubTitle: '',
      vasionSnackbarTitle: '',
    }
  },
  computed: {
    activeLookup() { return this.$store.state.fieldMapping.activeDatabaseLookup },
    attributeFieldList() {
      let fieldList = this.$store.state.common.indexFields.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
      fieldList.push({name: "DocumentID", value: "DocumentID"})
      return fieldList
    },
    attributeFieldIsDocumentID() {
      return this.attributeField.name === "DocumentID" && this.attributeField.value === "DocumentID"
    },
    attributeFieldIsSelected() { return this.attributeField.value !== '' && this.attributeField.name !== '' },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    databaseConnectionList() {
      return this.$store.state.fieldMapping.databaseConnectionList.map((f) => {
        return {
          name: f.name,
          value: f.id,
        }
      })
    },
    lookupTitle() { return this.lookupId && this.lookupId > 0 ? this.activeLookup.name : 'Untitled Database Lookup' },
    shouldDeleteConnectionShow() { return this.databaseConnection !== '' && this.databaseConnection.value > 0 && this.databaseConnection.value !== this.databaseConnectionId },
  },
  watch: {
    attributeForm: function () {
      if (this.attributeForm && this.attributeForm.value > 0) {
        this.attributeField = {name: '', value: ''}
        this.attributeFormId = this.attributeForm.value
        this.$store.dispatch('common/getIndexFieldsForForm', this.attributeFormId)
      }
    },
  },
  async created() {
    if (!this.attributeFormList || this.attributeFormList.length === 0) {
      await this.$store.dispatch('attributeForm/getForms')
    }

    if (!this.databaseConnectionList || this.databaseConnectionList.length === 0) {
      await this.$store.dispatch('fieldMapping/getDatabaseConnections')
    }

    if (this.$route.params.lookupId && this.$route.params.lookupId > 0) {
      await this.$store.dispatch('fieldMapping/getDatabaseLookupDetails', this.$route.params.lookupId)
      if (this.activeLookup) {
        this.setLookupValues()
      }
    }
  },
  mounted() {
    this.queryTextArea = this.$refs.query
  },
  methods: {
    addConnectionClick() { this.toggleAddConnectionDialog(true) },
    addParameter() {
      const insertTextFormat = `{${this.attributeField.value}}`
      this.queryText = this.insertAtCursorPosition(this.queryText, insertTextFormat)
    },
    addResult() {
      const insertTextFormat = `hidden_${this.attributeField.value}`
      this.queryText = this.insertAtCursorPosition(this.queryText, insertTextFormat)
    },
    cancel() { this.$router.push({ name: 'DatabaseLookups' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    async connectionSaved() {
      await this.$store.dispatch('fieldMapping/getDatabaseConnections')
      this.toggleAddConnectionDialog(false)
    },
    async deleteConnectionClick() {
      if (this.databaseConnection === '' || this.databaseConnection.value <= 0) {
        this.vasionSnackbarTitle = 'ERROR'
        this.vasionSnackbarSubTitle = 'Please select a database connection to delete.'
        this.vasionSnackbarImage = false
      } else if (await this.$store.dispatch('fieldMapping/deleteDatabaseConnection', this.databaseConnection.value)) {
        this.vasionSnackbarTitle = 'SUCCESS'
        this.vasionSnackbarSubTitle = `Successfully deleted "${this.databaseConnection.name}".`
        this.vasionSnackbarImage = true
        await this.$store.dispatch('fieldMapping/getDatabaseConnections')
        if (this.databaseConnection.value === this.databaseConnectionId) {
          this.databaseConnectionId = 0
        }
        this.databaseConnection = this.databaseConnectionList.find(c => c.id === this.databaseConnectionId) || ''
      } else {
        this.vasionSnackbarTitle = 'ERROR'
        this.vasionSnackbarSubTitle = `Unable to delete "${this.databaseConnection.name}".`
        this.vasionSnackbarImage = false
      }

      this.showVasionSnackbar = true
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    insertAtCursorPosition(baseText, insertText) {
       const cursorPosition = this.queryTextArea.selectionStart
       baseText = this.removeExtraWhitespace(baseText)
       return [baseText.slice(0, cursorPosition), insertText, baseText.slice(cursorPosition)].join('')
    },
    markDirty() { this.isDirty = true },
    removeExtraWhitespace(text) {
      // This method is necessary to support the "insert at cursor" functionality.
      // It removes extra spaces, tabs, and new lines
      return text.replace(/\s\s+/g, ' ')
    },
    async save() {
      if (await this.validate()) {
        const payload = {
          id: this.lookupId,
          name: this.lookupName,
          dbConnectionID: this.databaseConnection.value,
          queryText: this.removeExtraWhitespace(this.queryText),
          attributeFormID: this.attributeForm.value,
          defaultLookup: this.defaultLookup,
        }

        if (await this.$store.dispatch('fieldMapping/saveDatabaseLookup', payload)) {
          this.vasionSnackbarTitle = 'Success'
          this.vasionSnackbarSubTitle = 'Database Lookup saved successfully'
          this.vasionSnackbarImage = true
          this.showVasionSnackbar = true
          this.isDirty = false
          setTimeout(() => {
            this.$router.push({ name: 'DatabaseLookups' })
          }, 1500)
        } else {
          this.vasionSnackbarTitle = 'Error'
          this.vasionSnackbarSubTitle = 'Database Lookup NOT saved'
          this.vasionSnackbarImage = false
          this.showVasionSnackbar = true
        }
      }
    },
    setLookupValues() {
      this.attributeFormId = this.activeLookup.attributeFormID
      this.databaseConnectionId = this.activeLookup.dbConnectionID
      this.defaultLookup = this.activeLookup.defaultLookup
      this.lookupId = this.activeLookup.id
      this.lookupName = this.activeLookup.name
      this.queryText = this.removeExtraWhitespace(this.activeLookup.queryText)

      if (this.attributeFormId > 0) {
        this.$store.dispatch('common/getIndexFieldsForForm', this.attributeFormId)
      }

      this.attributeField = {name: '', value: ''}
      this.attributeForm = this.attributeFormList.find(f => f.value === this.attributeFormId) || ''
      this.databaseConnection = this.databaseConnectionList.find(c => c.value === this.databaseConnectionId) || ''
    },
    toggleAddConnectionDialog(value) {
      if (value !== undefined && value !== null && typeof value === 'boolean') {
        this.showAddConnectionDialog = value
      } else {
        this.showAddConnectionDialog = !this.showAddConnectionDialog
      }
    },
    toggleDefaultLookup() { this.defaultLookup = !this.defaultLookup },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate() {
      let error = false
      let errorText = ''
      this.vasionSnackbarTitle = 'ERROR'
      this.vasionSnackbarImage = false

      if (!this.lookupName || this.lookupName === '') {
        errorText += '- Please provide name for this database lookup.\n'
        error = true
      }
      if (this.lookupName.trim() === '') {
        errorText += '- The Name cannot only include spaces.\n'
        error = true
      }

      const connection = this.databaseConnection
      if (!connection || (!typeof type === 'object')
        || (!connection.name || connection.name === '')
        || (!connection.value || connection.value === '')) {
          errorText += '- Please provide a database connection.\n'
          error = true
      }

      const form = this.attributeForm
      if (!form || (!typeof type === 'object')
        || (!form.name || form.name === '')
        || (!form.value || form.value === '')) {
          errorText += `- Please provide ${$formsLabelWithPrefix.toLowerCase()}.\n`
          error = true
      }

      if (!this.queryText || this.queryText === '') {
        errorText += '- Please provide query text.\n'
        error = true
      }

      if (this.defaultLookup) {
        const validateDefaultLookupId = await parseInt(this.$store.dispatch('fieldMapping/getDefaultLookupId', this.attributeForm.value), 10)
        if (validateDefaultLookupId > 0 && validateDefaultLookupId !== this.lookupId) {
          errorText += '- This ${$formsLabel} already has a Default Lookup. Please uncheck "Default Lookup" before saving.\n'
          error = true
        }
      }

      if (error) {
        this.vasionSnackbarSubTitle = errorText
        this.showVasionSnackbar = true
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  #edit-database-lookup {
    #main-section {
      #lookup-details {
        .row {
          width: 100%;
          margin: 13px 0;
          display: flex;

          .section-title {
            @include SECTION-HEADER;
            color: $grey-400;
            display: block;
            margin: 5px 0;
          }

          .subheader {
            @include SubHeadline;
          }

          .row-field {
            margin: 0 14px 5px 0;

            &.checkbox {
              width: 390px;
            }
          }

          .vasion-button {
            &.add-database-connection-icon-button {
              float: left;
              margin-left: -10px;
              margin-right: 3px;
              margin-top: 20px;
            }
          }
        }
      }

      #lookup-preview {
        #content {
          textarea {
            width: 808px;
            height: 245px;
          }
        }
      }
    }
    
    ::v-deep .table-header {
      padding: 0px;
    }
    ::v-deep .vasion-html-table-default table td > div {
      padding: 0px;
    }
    .paddingRight {
      padding-right: 16px;
    }
  }
</style>
